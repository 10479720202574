import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

class HomeNav {
    constructor(next) {
        this.mobileNavElem = next.querySelector('#mobile-nav');
        this.hamburgerButton = next.querySelector('#hamburger');
        this.closeButton = next.querySelector('#closeNav');
        this.desktopScrollTriggers = [...next.querySelectorAll('.home-nav .scrTri')];
        this.mobileScrollTriggers = [...next.querySelectorAll('#mobile-nav .scrTri')];
        this.closeNav = function () {
            this.mobileNavElem.classList.add('closed');
        };
        this.openNav = function () {
            this.mobileNavElem.classList.remove('closed');
        };
        this.scrollToTarget = function (e) {
            e.preventDefault();
            const targetSelector = e.currentTarget.dataset.target;
            gsap.to(window, {
                duration: 0.5,
                scrollTo: { y: targetSelector, offsetY: 84 },
            });
        };
        this.scrollToTargetAndCloseNav = function (e) {
            e.preventDefault();
            const targetSelector = e.currentTarget.dataset.target;
            gsap.to(window, {
                duration: 0.5,
                scrollTo: { y: targetSelector, offsetY: 84 },
                onComplete: () => {
                    this.closeButton.click();
                },
            });
        };
        this.handleCloseNav = this.closeNav.bind(this);
        this.handleOpenNav = this.openNav.bind(this);
        this.handleSmoothScroll = this.scrollToTarget.bind(this);
        this.handleScrollToTargetAndCloseNav = this.scrollToTargetAndCloseNav.bind(this);
        this.addEvents();
    }
    addEvents() {
        this.hamburgerButton.addEventListener('click', this.handleOpenNav);
        this.closeButton.addEventListener('click', this.handleCloseNav);
        this.desktopScrollTriggers.forEach(elem => {
            elem.addEventListener('click', this.handleSmoothScroll);
        });
        this.mobileScrollTriggers.forEach(elem => {
            elem.addEventListener('click', this.handleScrollToTargetAndCloseNav);
        });
    }
    kill() {
        this.hamburgerButton.removeEventListener('click', this.handleOpenNav);
        this.closeButton.removeEventListener('click', this.handleCloseNav);
        this.desktopScrollTriggers.forEach(elem => {
            elem.removeEventListener('click', this.handleSmoothScroll);
        });
        this.desktopScrollTriggers.forEach(elem => {
            elem.removeEventListener('click', this.handleScrollToTargetAndCloseNav);
        });
    }
}

export { HomeNav };
