import { gsap } from 'gsap';
import { scrollToTarget } from './scrollToTarget';

const enterTransitionBasic = (next, scrollTarget = undefined) => {
    const tl = gsap.timeline({
        defaults: { duration: 0.75 },
        onStart() {
            if (scrollTarget) {
                scrollToTarget(next, scrollTarget);
            }
        },
    });
    tl.set(next.querySelector('main'), { y: 100, duration: 0 });
    tl.to('#transformer', { yPercent: -100 }, 0.25);
    tl.to(next.querySelector('main'), { y: 0, ease: 'power1.inOut' }, 0.25);
    tl.to('.transformer__mask', { yPercent: 110 }, 0.25);

    tl.set('#transformer', { yPercent: 100 });
    tl.set('.transformer__mask', { yPercent: 0 });
    tl.set(['.progressCounter', '.loaderBar'], { display: 'none' });
};

export { enterTransitionBasic };
