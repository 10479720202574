  // import Swiper bundle with all modules installed
  import Swiper from 'swiper/bundle';

  // init Swiper:

  const createSwiper = (next) => {
    const swiperElem = next.querySelector('.swiper-container')
    const swiper = new Swiper(swiperElem, {
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      speed: 1000,

      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
      },

      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      // And if we need scrollbar
      // scrollbar: {
      //   el: '.swiper-scrollbar',
      // },
    });
    return swiper
  }

  export {
    createSwiper
  }