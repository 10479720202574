import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

const scrollToTop = next => {
    gsap.to(window, {
        duration: 0,
        scrollTo: { y: 0 },
    });
};

export { scrollToTop };
