 const coordinates = [{
     "name": "Zehlendorf Mitte",
     "coordinates": [13.259134489114617, 52.43852438869779],
     "sbahn": false
 }, {
     "name": "Teltower Damm",
     "coordinates": [13.2599256, 52.4349053],
     "sbahn": false
 }, {
     "name": "Strandbad Wannsee",
     "coordinates": [13.1794272, 52.4380942],
     "sbahn": false
 }, {
     "name": "City West",
     "coordinates": [13.3316051, 52.5020207],
     "sbahn": false
 }, {
     "name": "Zentrum Potsdam",
     "coordinates": [13.0619922, 52.3952122],
     "sbahn": false
 }, {
     "name": "Bahnhof Zehlendorf",
     "coordinates": [13.2585763, 52.4311127],
     "sbahn": true
 }, {
     "name": "Bahnhof Wannsee",
     "coordinates": [13.1798077, 52.4214071],
     "sbahn": false
 }, {
     "name": "Bahnhof Nikolassee",
     "coordinates": [13.1928616, 52.4322435],
     "sbahn": true
 }, {
     "name": "FU Campus Düppel",
     "coordinates": [13.237617, 52.4289763],
     "sbahn": false
 }]

 export {
     coordinates
 }