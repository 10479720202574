import tippy from 'tippy.js';

class MyObject {
    constructor() {
        (this.messages = [
            'Gesamtmietfläche: ca. 12.800 m²',
            'Kleinste Mietfläche: ab 250 m²',
            '9.404 m² Grundstück',
            'DGNB Platin Zertifizierung angestrebt',
            'ca. 90 Stellplätze',
            'ca. 50 Werkswohnungen',
            'höchste Betriebskosteneffizient',
            'Fertigstellung ca. Q1/2023',
            'Hybride Bauweise',
        ]),
            (this.tooltipps = []);
        this.initToolTipps();
    }
    initToolTipps() {
        this.messages.forEach((content, index) => {
            const tipp = tippy(`#m${index}`, {
                content,
                placement: 'right',
                theme: 'greenovation',
            });
            this.tooltipps.push(tipp[0]);
        });
    }
    kill() {
        this.tooltipps.forEach(tipp => {
            tipp.destroy();
        });
        this.tooltipps = [];
    }
}

export { MyObject };
