import gsap from 'gsap/gsap-core';
import imagesLoaded from 'imagesloaded';
import { enterTransitionBasic } from './enterTransition_basic';

const percentLoad = { score: 0 };
const loaderBar = document.querySelector('.loaderBar');
const counterWrapper = document.querySelector('.progressCounter');
const loaderCountEl = document.querySelector('#count');

const preloader = next => {
    window.gsap = gsap;
    let loadedImagesCount = 0;
    const body = document.querySelector('body');
    const imgLoad = imagesLoaded(body);
    const imagesDone = false;

    const totalImages = imgLoad.images.length;
    gsap.set(body, { autoAlpha: 1 });

    if (totalImages > 0 && percentLoad.score < 100) {
        imgLoad.on('progress', function (instance, image) {
            image.isLoaded && loadedImagesCount++;
            const progress = loadedImagesCount / totalImages;
            removePreloaderTweens(progress, next);
        });
    } else {
        gsap.set(counterWrapper, { display: 'none' });
        removePreloaderTweens(1, next);
    }
};

const removePreloaderTweens = (progress, next) => {
    gsap.to(loaderBar, { scaleX: progress, ease: 'power4.out', duration: 1.25 });
    gsap.to(
        percentLoad,
        {
            score: progress * 100,
            ease: 'none',
            duration: 2.25,
            onUpdate() {
                updateHandlerCounter();
            },
            onComplete() {
                setTimeout(() => {
                    progress === 1 && handleReady(next);
                }, 300);
            },
        },
        0
    );
};

const handleReady = next => {
    enterTransitionBasic(next);
};

const updateHandlerCounter = () => {
    loaderCountEl.innerHTML = Math.floor(percentLoad.score);
};

export { preloader };
