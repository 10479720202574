import '../styles/main.scss';
import barba from '@barba/core';

import { addBrowserClass } from './utils/browser-class';

import {
    enterTransitionBasic,
    leaveTransitionBasic,
    scrollToTarget,
    scrollToTop,
    preloader,
} from './animation/index';

import {
    AjaxForm,
    HomeNav,
    MyObject,
    createSwiper,
    createVimeoVideoPlayer,
    createMapbox,
} from './components/index';
import './components/Galerie/Galerie';

// Pre Testiong

let myObject, galerie, ajaxForm, mySwiper, myPlayer, myMap, myHomeNav;

barba.init({
    debug: false,
    transitions: [
        {
            name: 'custom-transition',
            from: {
                namespace: ['slider', 'video'],
            },
            to: {
                namespace: ['home'],
            },
            once() {},
            async leave(data) {
                await leaveTransitionBasic(data.current.container);
            },
            enter(data) {
                const jumpToSelector = data.current.namespace === 'video' ? '#vid' : '#gal';
                enterTransitionBasic(data.next.container, jumpToSelector);
            },
        },
        {
            name: 'default-transition',
            once() {},
            async leave(data) {
                await leaveTransitionBasic(data.current.container);
            },
            beforeEnter() {
                scrollToTop();
            },
            enter(data) {
                enterTransitionBasic(data.next.container);
            },
        },
    ],
    views: [
        {
            namespace: 'home',
            async beforeEnter(data) {
                return new Promise((reject, resolve) => {
                    createMapbox(data.next.container).then(result => {
                        myMap = result;
                        myObject = new MyObject();
                        myHomeNav = new HomeNav(data.next.container);
                        ajaxForm = new AjaxForm(data.next.container);

                        resolve();
                    });
                });
            },
            afterEnter(data) {
                //   setTimeout(() => {
                //     myMap = createMapbox(data.next.container);
                //   }, 3000);
            },
            beforeLeave() {
                ajaxForm.kill();
                myObject.kill();
                myMap.remove();
                myHomeNav.kill();
            },
        },
        {
            namespace: 'meta',
            afterEnter(data) {},
            beforeLeave() {},
        },
        {
            namespace: 'slider',
            afterEnter(data) {
                mySwiper = createSwiper(data.next.container);
            },
            beforeLeave() {
                mySwiper.destroy();
            },
        },
        {
            namespace: 'video',
            async beforeEnter(data) {
                return new Promise((reject, resolve) => {
                    myPlayer = createVimeoVideoPlayer(data.next.container);
                    resolve(myPlayer.ready());
                });
            },
            beforeLeave() {
                myPlayer.destroy();
            },
        },
    ],
});

// Global Hooks
barba.hooks.once(data => {
    console.log('once hook');
    addBrowserClass();
    preloader(data.next.container);
});
