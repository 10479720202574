import { gsap } from "gsap";

import Player from "@vimeo/player";

const createVimeoVideoPlayer = (next) => {
  const playerWrapper = next.querySelector("#video-container");
  // const playButton = next.querySelector("#playButton");

  const player = new Player(playerWrapper, {
    id: 508364355,
    responsive: true,
    controls: false,
    autoplay: true,
    dnt: true,
    portrait: false,
    title: false,
    byline: false,
    color: "2f9d71",
  });

  player.on("loaded", function () {
    gsap
      .timeline({
        defaults: { duration: 1 },
        //    onComplete(){
        //        playButton.click();
        //    }
      })
      .from(playerWrapper, {
        y: -50,
      })
      .to(playerWrapper, {
        opacity: 1,
        duration: 1,
      });
  });
  return player;
};

export { createVimeoVideoPlayer };
