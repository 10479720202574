import { gsap } from 'gsap';

const leaveTransitionBasic = (current) => {


    const tl = gsap.timeline({ defaults: { duration: .75, ease: 'power1.inOut' } })
        .to(current, {
            y: -150,
        })
        .to('#transformer', { yPercent: 0 }, 0)
        .from('.transformer__mask', { yPercent: -110 }, 0)
    return tl
}

export { leaveTransitionBasic };