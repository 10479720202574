var mapboxgl = require("mapbox-gl/dist/mapbox-gl.js");
import { coordinates } from "./utils/coordinates";

const features = coordinates.map((obj) => {
  const sBahnIcon =
    '<img style="vertical-align:baseline; transform:translateY(4px)" src="./assets/img/mapIcons/de-s-bahn.svg">';
  return {
    type: "Feature",
    properties: {
      description: `${obj.name} ${obj.sbahn === true ? sBahnIcon : ""}`,
    },
    geometry: {
      type: "Point",
      coordinates: obj.coordinates,
    },
  };
});

const createMapbox = (next) => {
  return new Promise((resolve, reject) => {
    const wrapSelector = "mapboxWrap";
    const wrap = next.querySelector("#" + wrapSelector);

    mapboxgl.accessToken =
      "pk.eyJ1IjoiZ3JlZW5vdmF0aW9uIiwiYSI6ImNra3RpbWJjNTFlMXgycXFuc3RrendyaHoifQ.QUgy5hErJuQy4DwjQk8Frw";
    const map = new mapboxgl.Map({
      container: wrapSelector, // container id
      style: "mapbox://styles/greenovation/ckkttzyb22can17mvzyeo4mxs", //stylesheet location
      center: [13.2373, 52.428526], // starting position
      zoom: 12.5, // starting zoom
    });

    map.on("load", function () {
      map.loadImage(
        "./assets/img/mapIcons/marker-mit-schatten.png",
        // Add an image to use as a custom marker
        function (error, image) {
          if (error) throw error;
          map.addImage("custom-marker", image);

          map.addSource("places", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features,
            },
          });

          // Add a layer showing the places.
          map.addLayer({
            id: "places",
            type: "symbol",
            source: "places",
            layout: {
              "icon-image": "custom-marker",
              "icon-allow-overlap": false,
            },
          });
        }
      );

      // Create a popup, but don't add it to the map yet.
      var popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        anchor: "left",
        offset: 20,
      });

      // if (window.matchMedia("(pointer: coarse)").matches) {
      map.on("click", "places", function (e) {
        // Change the cursor style as a UI indicator.
        map.getCanvas().style.cursor = "pointer";
        var coordinates = e.features[0].geometry.coordinates.slice();
        var description = e.features[0].properties.description;
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.setLngLat(coordinates).setHTML(description).addTo(map);
      });
      // }

      map.on("mouseenter", "places", function (e) {
        // Change the cursor style as a UI indicator.
        map.getCanvas().style.cursor = "pointer";

        var coordinates = e.features[0].geometry.coordinates.slice();
        var description = e.features[0].properties.description;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.setLngLat(coordinates).setHTML(description).addTo(map);
      });

      map.on("mouseleave", "places", function () {
        map.getCanvas().style.cursor = "";
        popup.remove();
      });

      wrap.style.opacity = 1;
      resolve(map);
    });
    // setTimeout(() => {
    // }, 1500);
  });
};

export { createMapbox };
