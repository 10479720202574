const addBrowserClass = () => {
  window.onload = function () {
    //  alert(navigator.userAgent);
    if (navigator.userAgent.indexOf("Firefox") > 0) {
      document.querySelector("body").classList.add("ff");
    }
  };
};

export { addBrowserClass };
