import { gsap } from "gsap";

class AjaxForm {
  constructor(next) {
    this.contactForm = next.querySelector("#contact-form");
    this.emailField = next.querySelector("#email");
    this.nameField = next.querySelector("#name");
    this.telephoneField = next.querySelector("#telephone");
    this.messageField = next.querySelector("#message");

    this.messageEl = next.querySelector("#msg");

    this.submitAjax = function (e) {
      const that = this;
      e.preventDefault();
      const request = new XMLHttpRequest();
      const url =
        "https://developer.exactly-webdesign.de/mailservice/mailServiceGreenovation.php";
      const data = JSON.stringify({
        email: this.emailField.value,
        name: this.nameField.value,
        telephone: this.telephoneField.value,
        message: this.messageField.value,
      });

      request.open("POST", url, true);
      request.setRequestHeader("Content-Type", "application/json");
      request.onreadystatechange = function () {
        if (request.readyState === 4 && request.status === 200) {
          var jsonData = JSON.parse(request.response);

          console.log(jsonData);
          that.messageEl.innerHTML = jsonData.msg;
          gsap.to(that.messageEl, { autoAlpha: 1, duration: 1.25 });
          if (jsonData.status == "200") {
            that.clearFields();
            gsap.to(that.messageEl, { delay: 6, autoAlpha: 0 });
          }
        }
      };
      request.send(data);
    };
    this.handleSubmitAjax = this.submitAjax.bind(this);
    this.addAjaxEvent();
  }
  addAjaxEvent() {
    this.contactForm.addEventListener("submit", this.handleSubmitAjax);
  }
  clearFields() {
    [
      this.emailField,
      this.nameField,
      this.telephoneField,
      this.messageField,
    ].forEach((field) => {
      field.value = "";
    });
  }
  kill() {
    this.contactForm.removeEventListener("submit", this.handleSubmitAjax);
  }
}

export { AjaxForm };
